#modal-company--history {
    $shadowSize: pxToRem(6);
    
    .modal-header {
        padding: {
            bottom: pxToRem(65);
        }
    }
    
    .modal-body {
        padding: {
            right: pxToRem(24);
            left: pxToRem(24);
            bottom: pxToRem(45);
        }
    }
    
    .title-paragraph, .no-protocols {
        margin: {
            left: $shadowSize;
            right: $shadowSize;
        }
    }
    
    .title-text {
        font: {
            weight: $fontWeightBold;
        }
    }
    
    .date-container {
        background-color: $white;
        @include box-shadow(0px 3px $shadowSize rgba(0, 0, 0, 0.16));
        margin: {
            left: $shadowSize;
            right: $shadowSize;
            bottom: pxToRem(40);
        }
        padding: 0 pxToRem(12) pxToRem(1);
    }
    
    .history-container {
        padding-top: pxToRem(10);
        max-height: pxToRem(630);
        overflow: {
            y: auto;
        }
    }
    
    .inline-date {
        $dateHeight: pxToRem(50);
        position: relative;
        height: $dateHeight;
        
        span {
            background: {
                color: $white;
            }
            display: inline-block;
            position: relative;
            font: {
                size: pxToRem(13);
                weight: $fontWeightRegular;
            }
            line-height: $dateHeight;
            width: pxToRem(75);
        }
        
        &::before {
            content: "";
            position: absolute;
            top: calc(50% - 1px);
            left: 0;
            width: 100%;
            height: 0;
            border: {
                bottom: 2px solid $black;
            }
        }
        
        //Every date should have a lot of space top except the first
        &:not(:first-child) {
            margin-top: pxToRem(40);
        }
    }
    
    .history-content {
        font: {
            weight: $fontWeightRegular;
        }
    }
    
    .history-record {
        padding: pxToRem(10);
        font-size: pxToRem(13);
        margin-bottom: pxToRem(10);
        background: {
            color: $ci_background_grey_light;
        }
        
        .history-record-date {
            float: right;
            font: {
                weight: $fontWeightRegular;
            }
        }
        
        .history-record-answer {
            display: block;
            padding-top: pxToRem(5);
            font: {
                style: italic;
                weight: $fontWeightLight;
            }
            color: $font_grey;
        }
        
        // toDo: [stefan] Noch gültig?
        &.positive-answer {
            background-color: #F1FAF3;
            
            .history-record-answer {
                color: #00B233;
            }
        }
    
        // toDo: [stefan] Noch gültig?
        &.negative-answer {
            background-color: #FAF2F3;
            
            .history-record-answer {
                color: #CC0018;
            }
        }
    }
}
$step1Color: transparentize($black, 0.7);
$step2Color: transparentize($ci_red, 0.7);
$step3Color: transparentize($ci_yellow, 0.7);

footer {
    // Color gradient
    // todo fw: use color variables;
    @include linear-gradient-multi(
            $startFrom: left, $endTo: right,
            $firstPos: 0%, $firstColor: $step1Color,
            $secondPos: 50%, $secondColor: $step2Color,
            $thirdPos: 100%, $thirdColor: $step3Color
    );
    padding: 20px 0;
    
    .nav {
        & > .nav-item:first-child .nav-link {
            padding-left: 0;
        }
        
        .nav-link {
            color: $white;
            font-size: 0.9em;
            font-weight: $fontWeightMedium;
            padding-bottom: 0;
        }
    }
}

@media only screen and (max-width: $bootstrap-sm-max) {
    footer {
        padding: {
            top: 0;
            bottom: 0;
        }
        @include linear-gradient-multi(
                $startFrom: top left, $endTo: bottom right,
                $firstPos: 0%, $firstColor: $step1Color,
                $secondPos: 50%, $secondColor: $step2Color,
                $thirdPos: 100%, $thirdColor: $step3Color
        );
        
        .container {
            padding: 0;
        }
        
        .d-flex {
            display: block!important;
        }
        
        .nav {
            width: 100%;
    
            .nav-item {
                display: block;
                width: 100%;
            }
    
            .nav-link {
                padding: {
                    top: 0;
                    left: 0;
                    right: 0;
                }
                text-align: center;
                line-height: pxToRem(50);
                border: {
                    bottom: pxToRem(3) solid $white;
                }
                font: {
                    weight: $fontWeightRegular;
                }
            }
        }
        
        .back-to-top {
            display: block;
            width: 100%;
            text-align: center;
            padding: {
                top: pxToRem(15);
                bottom: pxToRem(15);
            }
        }
    }
}
$questionModalPadding: pxToRem($questionModalPaddingInPx);
$buttonSize: pxToRem($globalButtonSizeInPx);

.modal {
    .modal-content {
        background: {
            color: $ci_background_gray;
        }
    }
    
    .modal-header {
        border: {
            bottom: {
                width: 0;
            }
        }
        padding: {
            top: 0;
            left: $questionModalPadding;
            right: $questionModalPadding;
            bottom: pxToRem(25);
        }
        position: relative;
        text-align: center;
        flex-wrap: wrap;
        
        .close {
            position: absolute;
            top: 0;
            right: 0;
            background: {
                color: $ci_yellow;
            }
            opacity: 1;
            margin: 0;
            width: $buttonSize;
            height: $buttonSize;
            text-align: center;
            line-height: $buttonSize;
            padding: 0;
            text-shadow: 0 0 0 transparent;
            font: {
                size: pxToRem(18);
            }
        }
    }
    
    .modal-logo {
        margin: {
            top: pxToRem(40);
            left: auto;
            right: auto;
        }
        width: pxToRem(60);
        height: pxToRem(65);
    }
    
    .modal-title {
        color: $modalTitleColor;
        flex: 0 1 100%;
        font: {
            size: pxToRem(30);
            weight: $fontWeightRegular;
        }
        line-height: 1;
        margin: {
            top: pxToRem(20);
        }
    }
    
    .modal-body {
        padding: {
            top: 0;
            left: $questionModalPadding;
            right: $questionModalPadding;
            bottom: 0;
        }
    }
    
    .modal-dialog {
        &.modal-lg {
            max-width: pxToRem(1140);
        }
    }
}

.modal.modal-modalTable--ajax {
    .modal-dialog {
        width: pxToRem(600);
        max-width: 100%;
    }
    
    .modal-header {
        padding: {
            bottom: pxToRem(35);
        }
    }
    
    .modalTable-form {
        width: pxToRem(340);
        max-width: 100%;
        margin: {
            left: auto;
            right: auto;
        }
    }
    
    .form-group {
        margin: {
            bottom: pxToRem(10);
        }
    }
    
    .control-label {
        font: {
            size: pxToRem(12);
            weight: $fontWeightMedium;
        }
        line-height: 1;
        margin: {
            bottom: pxToRem(10);
        }
    }
    
    [type="submit"] {
        width: 100%;
        background: {
            color: $ci_green;
        }
        color: $white;
    }
    
    #user-executive {
        label {
            margin: {
                bottom: 0;
            }
        }
    }
    
    .required-info {
        margin: {
            bottom: pxToRem(20);
        }
        
        small {
            font: {
                size: pxToRem(12);
                weight: $fontWeightLight;
            }
        }
    }
    
    form {
        & > :last-child {
            margin: {
                bottom: pxToRem(60);
            }
        }
    }
}

#questionnaireAssignModal {
    .modal-header {
        padding: {
            bottom: pxToRem(45);
        }
    }
}

#modal--add-child-questionnaire {
    background: {
        color: rgba(0, 0, 0, 0.5);
    }
}

#modal-selfuser--update {
    [type="submit"] {
        display: block;
        margin: {
            left: auto;
            right: auto;
        }
    }
}
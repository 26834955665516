$navItemPadding: pxToRem(8);
$headerDropDownPadding: pxToRem(15);

header {
    ul.nav li.dropdown:hover ul.dropdown-menu {
        display: block;
    }

    .navbar {
        padding: {
            top: 0;
            bottom: 0;
        }
        background: {
            color: $white;
        }
    }
    
    .header--bottom {
        flex: 1 0 100%;
        background: {
            color: $black;
        }
        height: pxToRem($headerBottomHeight);
        margin: {
            left: pxToRem(-16);
            right: pxToRem(-16);
        }
    }
    
    .navbar-nav {
        & > li {
            padding: {
                left: 0;
                right: 0;
            }
        }
        
        .nav-link {
            font-weight: $fontWeightMedium;
            color: $white;
            padding: {
                top: 1.5rem;
                bottom: 0.8rem;
            }
            line-height: pxToRem(24);
        }
        
        .active > .nav-link {
            color: $ci_yellow;
        }
    }
    
    .dropdown-menu {
        background: {
            color: $ci_yellow;
        }
        padding: {
            top: 0;
            left: pxToRem(30);
            right: $headerDropDownPadding;
        }
        margin: {
            top: pxToRem(-8);
        }
        line-height: pxToRem(35);
        font: {
            size: pxToRem(14);
        }
        border: {
            radius: 0;
            width: 0;
        }
        left: $navItemPadding;
        // hack for IE/Edge who render bullet for on pageload hidden ul´s
        list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
        
        li {
            a {
                display: block;
                
                &:hover {
                    color: $white;
                    text-decoration: none;
                }
            }
            
            &.active {
                a {
                    color: $white;
                    font: {
                        weight: $fontWeightMedium;
                    }
                }
            }
        }
    }
    
    .dropdown-toggle {
        &::after {
            display: none;
        }
    }
    
    .navbar-profile {
        cursor: pointer;
    }
    
    .navbar-brand {
        padding: 0;
        background: {
            color: $white;
        }
        
        .header-logo {
            width: pxToRem(150);
            height: pxToRem(50);
            padding: {
                left: pxToRem(17);
                right: pxToRem(17);
            }
        }
    }

    .language-flag {
       border: none;
       width: pxToRem(150);
       height: pxToRem(50);
    }
    
    .navbar-dark {
        .navbar-nav {
            .nav-link {
                &:hover, &:focus {
                    color: $ci_yellow;
                }
            }
        }
    }
}

@media only screen and (max-width: $bootstrap-md-max) {
    header {
        .desktop-background {
            display: none;
        }
        
        .header--bottom {
            & > .container {
                background: {
                    color: $black;
                }
            }
        }
        
        .navbar-collapse {
            border: {
                top: pxToRem(1) solid $black;
            }
        }
    
        .navbar-nav, .navbar-dark .navbar-nav {
            .nav-link {
                min-height: pxToRem(60);
                padding: pxToRem(10) pxToRem(15);
                color: $ci_yellow;
                border: {
                    bottom: pxToRem(1) solid $ci_yellow;
                }
                font: {
                    weight: $fontWeightRegular;
                }
                
                &::before {
                    content: "";
                    display: inline-block;
                    height: 100%;
                    min-height: pxToRem(40);
                    width: 0;
                    vertical-align: middle;
                }
            }
        
            .active > .nav-link {
                border-bottom-width: 0;
                background: {
                    color: $ci_yellow;
                }
                color: $black;
            }
        }
        
        .spacer {
            height: pxToRem(115);
        }
    
        .top-footer-menu.navbar-nav {
            border: {
                top: pxToRem(1) solid $white;
            }
            @include linear-gradient-multi(
                0%, transparentize($black, 0.4),
                50%, transparentize($ci_red, 0.4),
                100%, transparentize($ci_yellow, 0.4),
                top left, bottom right
            );
            
            .nav-link {
                color: $white;
                border-bottom-color: $white;
            }
        }
        
        .navbar-text {
            color: $white;
            padding: {
                top: 0;
                bottom: 0;
            }
            width: 100%;
    
            & > .nav-link {
                width: 100%;
                padding: {
                    top: 0;
                    bottom: 0;
                    left: pxToRem(10);
                    right: pxToRem(10);
                }
                background: {
                    color: $ci_red;
                }
                color: $white;
                line-height: pxToRem(60);
                height: pxToRem(60);
                text-align: right;
            }
            
            
            .text {
                display: inline-block;
                vertical-align: middle;
                float: left;
            }
            
            .logout-icon {
                vertical-align: middle;
            }
        }
    }
}

@media only screen and (min-width: $bootstrap-lg) {
    header {
        .navbar {
            height: pxToRem($headerHeight);
            flex-wrap: wrap;
        }
    
        .desktop-background {
            $breakSpace: pxToRem(8);
            display: block;
            flex: 1 0 100%;
            height: pxToRem($headerTopHeight);
            text-align: right;
            color: $black;
            line-height: pxToRem($headerTopHeight);
            font: {
                size: pxToRem(14);
            }
            margin: {
                left: pxToRem(-16);
                right: pxToRem(-16);
            }
            @include linear-gradient-multi(
                0%, transparentize($black, 0.2),
                50%, transparentize($ci_red, 0.2),
                66%, transparentize($ci_yellow, 0.2),
                left, right
            );
            
            .break {
                margin: {
                    left: $breakSpace;
                    right: $breakSpace;
                }
            }
            
            .user-icon {
                font: {
                    size: pxToRem(20);
                }
                margin: {
                    left: $breakSpace;
                    right: $breakSpace;
                }
            }
            
            span, .material-icons {
                display: inline-block;
                vertical-align: middle;
            }
        }
        
        .logout--desktop {
        
        }
        
        .header--bottom {
            & > .container {
                flex-wrap: nowrap;
                align-items: center;
                display: flex;
                justify-content: space-between;
            }
        }
        
        .navbar-collapse {
            height: pxToRem($headerBottomHeight);
        }
        
        .navbar-nav {
            & > li {
                padding: {
                    left: $navItemPadding;
                    right: $navItemPadding;
                }
            }
        }
        
        .dropdown-menu {
            padding: {
                top: pxToRem(8);
                left: $headerDropDownPadding;
            }
            margin: {
                top: pxToRem(-$headerActiveBorder);
            }
        }
        
        .navbar-brand {
            margin: {
                top: pxToRem(-$headerTopHeight);
            }
            
            .header-logo {
                width: pxToRem(190);
                height: pxToRem($headerHeight);
            }
        }
    
        .top-footer-menu.navbar-nav {
            display: none;
        }
        
        .navbar-expand-lg {
            .navbar-nav {
                .nav-link {
                    padding: {
                        left: pxToRem(12);
                        right: pxToRem(12);
                        top: pxToRem($headerActiveBorder);
                        bottom: 0;
                    }
                    line-height: pxToRem($headerBottomHeight - ($headerActiveBorder * 2));
                }
                
                .active {
                    & > .nav-link {
                        border-bottom: pxToRem($headerActiveBorder) solid $ci_yellow;
                    }
                }
            }
        }
        
        .navbar-dark {
            .navbar-nav {
                .nav-link {
                    color: $white;
                    font: {
                        weight: $fontWeightRegular;
                    }
                }
                
                .active {
                    .nav-link {
                        color: $ci_yellow;
                    }
                }
            }
            
            .navbar-text {
                display: none;
            }
        }
    }
}

//WFL device-pixel-ratio mixin
//===============================
// @version 1.0.0
//

@mixin wfl-device-pixel-ratio($device-pixel-ratio: 1, $minOrMax: min) {
    @if ($minOrMax != min) and ($minOrMax != max) {
        $minOrMax: min;
    }
    
    @media only screen and (-webkit-#{$minOrMax}-device-pixel-ratio: $device-pixel-ratio),
     (#{$minOrMax}-resolution: #{$device-pixel-ratio * 96}dpi),
     (#{$minOrMax}-resolution: #{$device-pixel-ratio}dppx) {
        @content;
    }
}
$formInputHeightInPx: 50;
$formInputHeight: pxToRem($formInputHeightInPx);
$formLineHeight: pxToRem($formInputHeightInPx - 2);

.btn {
    font-size: 1rem;
    font-weight: $fontWeightMedium;
    background: $ci_yellow;
    color: $black;
    border: 0;
    @include border-radius(0);
    text-align: center;
    outline: 0 !important;
    padding: pxToRem(12) pxToRem(30);

    &.btn-max-width {
        width: 100%;
    }

    &:hover,
    &:active,
    &:focus {
        background: $ci_dark_yellow;
        color: $white;
    }

    &:disabled {

    }

    &.btn-link {
        font-weight: 400;
        color: #007bff;
        background-color: transparent;
    }
    
    &.btn-primary {
        background: $ci_red;
        color: $white;

        &:hover,
        &:active,
        &:focus {
            background: $ci_dark_red;
            color: $white;
        }
    }

    &.btn-secondary {
        background: $ci_light_yellow;
        color: $black;
        border: pxToRem(1) solid $ci_yellow;
        font-weight: 700;

        &:hover,
        &:active,
        &:focus {
            background: $ci_yellow;
            color: $white;
        }
    }

    &.btn-save {
        background: $ci_green;
        color: $white;
        border: pxToRem(2) solid $ci_green;
        padding: {
            top: pxToRem(11);
            bottom: pxToRem(11);
        }
        
        &:hover,
        &:active,
        &:focus {
            @include box-shadow(0 0 3px 3px rgba(0, 0, 0, 0.2));
        }

        &[disabled] {
            background: {
                color: $ci_light_green;
            }
        }
    }

    &.btn-delete {
        background: $ci_gray;
        color: $black;

        &:hover,
        &:active,
        &:focus {
            background: $ci_red;
            color: $white;
        }
    }

    &[disabled] {
        opacity: 0.3;
    }
}

// Override Bootstrap style
.custom-file-label {
    @include wfl-text-overflow();
    
    &::after {
        content: unset !important;
    }
}

form {
    @include wfl-required($showColon: false);
}

.form-control {
    border: {
        radius: 0;
    }
    
    &[type="text"], &[type="password"], &[type="file"] {
        padding: {
            top: 0;
            bottom: 0;
        }
        line-height: $formLineHeight;
        min-height: $formInputHeight;

        @include wfl-placeholder() {
            color: $font_grey;
        }
    }
}

.custom-file {
    height: $formInputHeight;
}

.custom-file-label {
    height: $formInputHeight;
    padding: {
        top: 0;
        bottom: 0;
    }
    line-height: $formLineHeight;
}

select.form-control {
    &:not([size]):not([multiple]) {
        line-height: $formLineHeight;
        height: $formInputHeight;
    }
}

.wfl-radio, .wfl-checkbox {
    @include wfl-checkbox($size: 22px, $iconColor: $white, $labelMargin: 8px);
    
    & + label {
        margin: {
            bottom: 0;
        }
        line-height: 22px;
    }
    
    input {
        &:checked {
            & + div {
                background: {
                    color: $black;
                }
            }
        }
    }
}

.wfl-radio {
    input[type="radio"] {
        & + div  {
            padding: {
                top: 6px;
                left: 2px;
            }
            bottom: 2px;
            
            .wfl-check {
                width: 8px;
                height: 8px;
            }
        }
    }
}

.wfl-checkbox {
    input[type="checkbox"] {
        & + div {
            .wfl-check {
                bottom: 4px;
                width: 6px;
                height: 12px;
            }
        }
    }
}

.dropdown {
    :not(.navbar-nav) > & {
        width: pxToRem(350);
        max-width: 100%;
        height: $formInputHeight;
        padding: {
            top: 0;
            bottom: 0;
        }
    
        .dropdown-toggle {
            text-align: left;
            padding: {
                top: 0;
                bottom: 0;
                right: pxToRem(20);
            }
            line-height: $formLineHeight;
            min-height: $formInputHeight;
            @include wfl-text-overflow();
        
            &::after {
                position: absolute;
                top: pxToRem(23);
                right: pxToRem(15);
            }
        }
    
        .dropdown-text {
            .dropdown-checked {
                display: none;
            }
        }
    
        .dropdown-menu {
            width: 100%;
            max-height: pxToRem(300);
            overflow: auto;
            padding: {
                top: 0;
                bottom: 0;
            }
        }
    
        .dropdown-item {
            position: relative;
            padding: 0;
        
            .text {
                display: block;
                height: 100%;
                margin: {
                    bottom: 0;
                }
                padding: {
                    left: pxToRem(15);
                    right: pxToRem(15);
                }
                line-height: $formInputHeight;
                min-height: $formInputHeight;
                @include wfl-text-overflow();
            }
    
        label {
            white-space: normal;
        }
        
            &:hover {
                .text {
                    background: {
                        color: $dropDownHover;
                    }
                }
            }
        }
    
        .required {
            label {
                &::after {
                    content: "";
                }
            }
        }
    
        .dropdown-radio {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        
            &:checked {
                & + .text {
                    background: {
                        color: $dropDownHover;
                    }
                
                    .dropdown-checked {
                        opacity: 1;
                    }
                }
            }
        }
    
        .dropdown-checked {
            opacity: 0;
            position: absolute;
            right: pxToRem(15);
            line-height: $formLineHeight;
            min-height: $formInputHeight;
        }
    }
}
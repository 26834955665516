$questionModalPadding: pxToRem($questionModalPaddingInPx);
$buttonSize: pxToRem($globalButtonSizeInPx);

.question-form {

    .text-field-options {
        margin-top: 20px;
        display: none;
        flex-wrap: wrap;

        &.active {
            display: flex;
        }

        .user-upload-select,
        .user-upload-help {
            flex-grow: 1;
        }
    }

    .answer-fields {
        display: none;
        margin: {
            top: pxToRem(20);
        }
        width: 100%;
        flex-wrap: wrap;
        
        &.active {
            display: flex;
        }
        
        .field-option:not(.field-template), .field-options {
            margin: {
                bottom: pxToRem(20);
            }
        }
        
        .field-option:not(.field-template) {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            
            .form-group, .answer-delete {
                flex: 0 1 auto;
            }

            .form-group {
                margin: {
                    right: pxToRem(30);
                }
    
                label {
                    display: block;
                }
            }
            
            .answer-delete {
                margin: {
                    bottom: 20px;
                }
                align-self: flex-end;
                font: {
                    size: pxToRem(18);
                }
            }
            
            .selected-child-questionnaires {
                width: 100%;
                background: {
                    color: $ci_background_grey_light;
                }
                border: {
                    radius: pxToRem(4);
                }
                padding: {
                    top: pxToRem(15);
                    left: pxToRem(20);
                    right: pxToRem(20);
                    bottom: pxToRem(5);
                }
    
                .child-questionnaire {
                    display: inline-block;
                    margin: {
                        right: pxToRem(10);
                        bottom: pxToRem(10);
                    }
                    color: $font_grey;
                    font: {
                        size: pxToRem(12);
                        weight: $fontWeightRegular;
                    }
                }
            }
    
            .answer-field-questionnaire,
            .user-upload-select {
                flex-basis: pxToRem(360);
        
                .dropdown {
                    width: 100%;
                    display: inline-block;
                }
                
                .form-control {
                    width: 100%;
                }
            }

            .user-upload-help {
                flex-basis: pxToRem(980);

                .form-control {
                    width: 100%;
                }
            }
        }
        
        .field-options {
            width: auto;
            flex: 0 1 pxToRem(270);
            
            &.flex-auto {
                flex-basis: auto;
            }
        }
        
        .form-control {
            width: pxToRem(200);
            display: inline-block;
        }
        
        .addAnswerFieldQuestionnaire {
            margin: {
                top: pxToRem(15);
            }
        }
        
        .answer-field-questionnaire-delete {
            width: $buttonSize;
            line-height: pxToRem($globalButtonLineHeight);
            text-align: center;
            float: right;
            cursor: pointer;
            display: inline-block;
        }
        
        .answer-delete {
        
        }
        
        .addField {
            width: $buttonSize;
            height: $buttonSize;
            font: {
                size: pxToRem(18);
            }
            padding: 0;
        }
    }
    
    .delete-icon {
        display: inline-block;
        width: $buttonSize;
        height: $buttonSize;
        line-height: pxToRem($globalButtonLineHeight);
        text-align: center;
        background-color: $ci_red;
        color: $white;
        cursor: pointer;
        float: right;
    
        &:hover {
            background: {
                color: $white;
            }
            color: $ci_red;
            border: pxToRem(1) solid $ci_red;
        }
    }
}

#add-question-button {
    width: 100%;
}

#questionForm {
    label {
        font: {
            size: pxToRem(12);
            weight: $fontWeightMedium;
        }
    }
    
    #question-title {
        font: {
            weight: $fontWeightMedium;
        }
    }

    #question-title-de {
        font: {
            weight: $fontWeightMedium;
        }
    }

    #question-title-en {
        font: {
            weight: $fontWeightMedium;
        }
    }
    
    .form-group {
        margin: {
            bottom: pxToRem(20);
        }
    }
    

    .mindmap-short-text {
        font: {
            weight: $fontWeightMedium;
        }
    }
    .addFile {
        width: $buttonSize;
        height: $buttonSize;
        line-height: $buttonSize;
        text-align: center;
        padding: 0;
        margin: {
            bottom: pxToRem(20);
        }
    }
    
    .viewFile {
        height: $buttonSize;
        line-height: $buttonSize;
        padding: {
            top: 0;
            bottom: 0;
        }
        @include wfl-text-overflow();
    }
    
    .deleteFile {
        line-height: pxToRem($globalButtonLineHeight);
    }
    
    .file-upload-select {
        & + .custom-file-label {
            padding: {
                top: 0;
                bottom: 0;
            }
        }
    }
    
    .files{
        .row {
            margin: {
                bottom: pxToRem(20);
            }
        }
        
        .col {
            & + .col {
                padding: {
                    left: pxToRem(5);
                }
            }
        }
        
        .form-group {
            margin: {
                bottom: 0;
            }
        }
    }
    
    .file-video-upload {
        flex-wrap: nowrap;
    }
    
    .col-file {
        flex: 0 0 pxToRem(370);
    }
    
    .col-upload {
        flex: 0 0 pxToRem(200);
    }
    
    .col-progress {
        flex: 0 1 auto;
        display: flex;
        align-items: center;
    }
    
    .progress {
        flex: 1 0 auto;
        height: pxToRem(20);

        //Override default background-color (blue from bootstrap)
        .progress-bar {
            background-color: #7f7f7f;
        }

    }
    
    .file-success {
        flex: 0 1 pxToRem(20);
        margin: {
            left: pxToRem(20);
        }
    }
    
    .btn-file-delete {
        flex: 0 1 pxToRem(20);
        margin: {
            left: pxToRem(14);
        }
        line-height: 1;
    }
    
    .child-questionnaire {
        position: relative;
    }
    
    & > :last-child {
        margin: {
            left: -$questionModalPadding;
            right: -$questionModalPadding;
            bottom: 0;
            top: pxToRem(60);
        }
        
        [type="submit"] {
            margin: {
                top: pxToRem(-10);
            }
        }
    }
    
    .visibility-rules {
        background: {
            color: $ci_background_grey_light;
        }
        padding: pxToRem(15);
    }
    
    .visibility-rule {
        display: flex;
        
        .form-group {
            flex: 1 1 auto;
            
            &.visibility-question {
                flex-basis: pxToRem(260);
            }
    
            &.visibility-field {
                flex-basis: pxToRem(220);
            }
    
            &.visibility-connect {
                flex-basis: pxToRem(100);
            }
            
            &.visibility-status {
                flex-basis: pxToRem(160);
            }
            
            &.visibility-group {
                flex-basis: pxToRem(180);
            }
        }
        
        .visibility-rule-delete {
            flex: 0 0 pxToRem(50);
            align-self: flex-end;
            margin: {
                bottom: pxToRem(20);
            }
        }
        
        .dropdown {
            width: auto;
        }
        
        label {
            display: block;
        }
    }
    
    .addVisibilityRule {
        width: $buttonSize;
        height: $buttonSize;
        font: {
            size: pxToRem(18);
        }
        padding: 0;
    }
}

.field-questionnaire-introduction {
    margin: {
        top: pxToRem(20);
    }
}

#questionnaire-files {
    .question-card-header {
        border: {
            right: $cardYellowBorder;
        }
        
        & > :first-child {
            border: {
                left-width: 0;
            }
        }
        
        & > :last-child {
            border: {
                right: $cardYellowBorder;
            }
        }
    }
}

#assign-user-form {
    .assign-info {
        margin: {
            bottom: pxToRem(25);
        }
    }
    
    .assign-status {
        vertical-align: middle;
    }
    
    .assign-title {
        display: inline-block;
        vertical-align: middle;
        font: {
            weight: $fontWeightBold;
        }
    }
    
    .assign-status {
        font: {
            size: pxToRem(30);
        }
        margin: {
            right: pxToRem(25);
        }
        
        &.status--outstanding {
            color: $ci_red;
        }
        
        &.status--in-work {
            color: $ci_yellow;
        }
        
        &.status--finished {
            color: $ci_green;
        }
    }
    
    .assign-text, .control-label {
        font: {
            weight: $fontWeightBold;
        }
        margin: {
            bottom: pxToRem(10);
        }
    }
    
    .assign-due-date {
        margin: {
            top: pxToRem(10);
            bottom: pxToRem(30);
        }
    }
    
    .btn-save {
        background: {
            color: $ci_green;
        }
        color: $white;
    }
}

@media all and (max-width: $bootstrap-sm-max) {
    #questionnaires-table {
        thead {
            //display: none;
        }
        
        tr {
            .td--questionnaire {
                $tdWidth: pxToRem(210);
                flex-basis: $tdWidth;
                max-width: $tdWidth;
                width: $tdWidth;
            }
    
            .td--employee {
                $tdWidth: pxToRem(250);
                flex-basis: $tdWidth;
                width: $tdWidth;
            }
    
            .td--assignedAt, .td--updatedAt, .td--due {
                $tdWidth: pxToRem(140);
                flex-basis: $tdWidth;
                max-width: $tdWidth;
                width: $tdWidth;
            }
    
            .td--updatedAt {
            }
    
            .td--due {
            }
        }
    }
    
    #questionForm {
        .visibility-rule {
            flex-wrap: wrap;
    
            .visibility-rule-delete {
                flex-basis: 100%;
            }
        }
    }
}

@media all and (min-width: $bootstrap-md) {
    #questionnaires-table {
        .responsive-status {
            display: none;
        }
    }
    
    #questionForm {
        .visibility-rule {
            .form-group {
                & + .form-group {
                    margin: {
                        left: pxToRem(15);
                    }
                }
            }
            
            .visibility-rule-delete {
                margin: {
                    left: pxToRem(15);
                }
            }
        }
    }
}
$cardYellowBorder: 1px solid $ci_yellow;
// do NOT replace $globalButtonSizeInPx with $globalButtonSize, it´s break the calc function
$optionsSize: pxToRem($globalButtonSizeInPx);

.questionnaire-container {
    flex: 1 0 auto;
    max-width: 100%;
    
    h1 {
        font: {
            size: pxToRem(24);
        }
    }
    
    &:not(#questionnaire-index) {
        background: {
            color: $ci_background_gray;
        }
    }
    
    header {
        align-items: center;
        
        .col-auto {
            max-width: 100%;
        }
    }
    
    .content-header {
        background: {
            color: $white;
        }
    }
}

#questionnaire-category-nav {
    $categoryHeight: 40;
    $borderWidth: 1;
    flex: 0 1 pxToRem($categoryHeight);
    border: {
        bottom: pxToRem($borderWidth) solid #B4B4B4;
    }
    margin: {
        bottom: pxToRem(10);
    }
    line-height: pxToRem($categoryHeight - $borderWidth);
    
    #category-list {
        list-style: none;
        padding: {
            left: 0;
        }
        margin: 0;
        
        li {
            a {
                font: {
                    size: pxToRem(12);
                }
                color: $font_grey;
                margin: {
                    right: pxToRem(15);
                }
            }
            
            &.active {
                a {
                    font: {
                        weight: $fontWeightBold;
                    }
                }
            }
        }
    }
}

.questions {
    margin: {
        bottom: pxToRem(40);
    }
    
    .question-card {
        @include clearfix();
        
        & + .question-card {
            margin: {
                top: pxToRem(30);
            }
        }
        
        &:not(.question-finished) {
            .question-answered {
                display: none;
            }
        }
    
        &.question-finished {
            cursor: pointer;
            
            .question-card-header {
                border: {
                    color: $ci_green;
                }
            }
            
            .question-card-title {
                background-color: $ci_green;
                color: $white;
            }
        
            .question-card-counter {
                background-color: $ci_green;
                color: $white;
                border: {
                    color: $ci_green;
                    left: pxToRem(1) solid $white;
                    right: pxToRem(1) solid $white;
                }
            }
        
            .question-card-files, .question-card-video {
                display: none;
            }

            .question-answered {
                display: block;
                background: {
                    color: $ci_green;
                }
                color: $white;
                border: {
                    color: $ci_green;
                }
            }
        
            .question-card-field {
                display: none;
            }
        
            .question-card-answer {
                display: none;
            }
            
            .question-card-edit {
                display: none;
            }
            
            .question-card-delete {
                display: none;
            }
        }
    }
    
    .question-card-header {
        background: {
            color: $ci_background_yellow;
        }
        border: {
            top: $cardYellowBorder;
            left: $cardYellowBorder;
            bottom: $cardYellowBorder;
        }
        display: flex;
        align-items: stretch;
    }
    
    .question-card-title {
        margin: {
            bottom: 0;
        }
        flex: 1 1 auto;
        font: {
            size: pxToRem(16);
            weight: $fontWeightMedium;
        }
        padding: pxToRem(14) pxToRem(20);
    }
    
    .question-card-counter {
        height: pxToRem(48);
        line-height: pxToRem(48);
        text-align: center;
        font: {
            size: pxToRem(24);
            weight: $fontWeightMedium;
        }
        border: {
            left: $cardYellowBorder;
            right: $cardYellowBorder;
        }
    }
    
    .question-card-counter, .question-card-video, .question-card-files, .question-answered {
        background: {
            color: $ci_light_yellow;
        }
        width: $optionsSize;
        min-height: $optionsSize;
        height: auto!important;
        text-align: center;
        flex: 0 1 $optionsSize;
        min-width: $optionsSize;
        
        .vertical-align {
            display: inline-block;
            vertical-align: center;
        }
    
        &::before {
            content: "";
            display: inline-block;
            width: 0;
            height: 100%;
            vertical-align: middle;
        }
    }
    
    .question-card-video, .question-card-files {
        border: {
            left: $cardYellowBorder;
        }
        
        &:hover {
            color: $ci_yellow!important;
        }
    }

    .question-card-sort {
        flex: 0 1 pxToRem(90);
        text-align: center;
    }

    .question-card-field {
        float: left;
        width: calc(100% - #{$optionsSize});
        min-height: pxToRem(100);
        background: {
            color: $white;
        }
        padding: pxToRem(30);
        box-shadow: pxToRem(-3) pxToRem(3) pxToRem(6) pxToRem(2) rgba(0, 0, 0, 0.16);

        .error-container {
            color: $ci_red;
        }

        .file-entry {
            padding: 10px 0;
        }
    }
    
    .question-card-options {
        float: right;
        width: $optionsSize;
    }
    
    .card-option {
        display: block;
        border: {
            left: $cardYellowBorder;
            right: $cardYellowBorder;
            bottom: $cardYellowBorder;
        }
        height: $optionsSize;
        text-align: center;
        line-height: $optionsSize;
        
        & + .card-option {
            border: {
                top: 0 none;
            }
        }
    
        &.question-card-edit {
            background: {
                color: $white;
            }
    
            &:hover {
                color: $ci_yellow!important;
            }
        }
        
        &.question-card-answer {
            background: {
                color: $ci_background_green;
            }
            border: {
                top: {
                    width: pxToRem(1);
                    style: solid;
                }
                color: $ci_green;
            }
            color: $ci_green;
        }
        
        &.question-card-delete {
            background: {
                color: $ci_red;
            }
            border: {
                top: {
                    width: pxToRem(1);
                    style: solid;
                }
                color: $ci_red;
            }
            color: $white;
        }
    }
}

.questionnaire-form {
    margin: {
        bottom: pxToRem(110);
    }
}

#questionnaireForm {
    margin: {
        top: pxToRem(40);
    }
    
    .control-label {
        font: {
            weight: $fontWeightMedium;
        }
    }
    
    .upload-row {
        .row {
            margin: {
                bottom: pxToRem(15);
            }
        }
    
        .form-control {
            width: calc(100% - #{pxToRem(75)});
        }
    
        .delete-icon {
            margin: {
                left: pxToRem(10);
            }
        }
    }
    
    .videos {
    
    }
    
    .video-input {
        margin: {
            left: pxToRem(15);
        }
    }
    
    .addVideo {
        display: block;
    }
    
    .files-introduction {
        .file-video-upload {
            margin: {
                bottom: pxToRem(20);
            }
        }
        
        .viewFile {
            line-height: pxToRem($globalButtonLineHeight);
            height: pxToRem($globalButtonLineHeight);
            padding: {
                top: 0;
                bottom: 0;
            }
            margin: {
                left: pxToRem(15);
            }
            @include wfl-text-overflow();
        }
        
        .col-file {
            flex: 1 0 50%;
        }
        
        .col-upload {
            flex: 1 0 pxToRem(200);
        }
        
        .col-progress {
            flex: 1 0 100%;
            margin: {
                top: pxToRem(10);
            }
            display: flex;
        }
    
        .progress {
            flex: 1 0 auto;
            height: pxToRem(20);
        
            //Override default background-color (blue from bootstrap)
            .progress-bar {
                background-color: #7f7f7f;
            }
        
        }
    
        .file-success {
            flex: 0 1 pxToRem(20);
            margin: {
                left: pxToRem(20);
            }
        }
    
        .btn-file-delete {
            flex: 0 1 pxToRem(20);
            margin: {
                left: pxToRem(14);
            }
            line-height: 1;
        }
        
        .addFile {
            display: block;
        }
    }
}

#questions-video-modal {
    .modal-dialog {
        width: pxToRem(1000);
        max-width: 100%;
    }
    
    .modal-header {
        padding: {
            bottom: 0;
        }
    }
    
    .modal-body {
        padding: pxToRem(55) pxToRem(25);
    }
    
    iframe {
        max-width: 100%;
        width: pxToRem(948);
        height: pxToRem(520);
    }
}

.answer-group-container {
    max-width: 100%;
    column-gap: pxToRem(30);
    @include column-count(3);
    column-width: pxToRem(180);
    
    .dropdown, .text {
        display: inline-block;
    }
}

.answer-row {
    @include column-break-inside(avoid);
    // prevent breaking of content / padding-bottom as safari hack because safari doesn´t like margin-bottom here
    padding: 1px 1px pxToRem(30);
    display: flex;
    align-items: center;
    
    label {
        -ms-word-break: break-word;
        word-break: break-word;
    }
}

.user-file-upload {

    .file-upload-info {
        margin-top: 15px;
    }
}

#questionnaire-index, #company-index, #user-index {
    header {
        h1 {
            font-size: pxToRem(24);
        }

        .form-group {
            margin: {
                bottom: 0;
            }
        }
    }
}

@media only screen and (max-width: $bootstrap-sm-max) {
    .questions {
        .question-card-header {
            flex-wrap: wrap;
        }
        
        .question-card-sort {
            flex-grow: 1;
        }
        
        .question-card-title {
            flex-basis: 100%;
        }
    }
    
    #questionnaire-files {
        padding: {
            left: pxToRem(15);
            right: pxToRem(15);
        }
    }
    
    #questionnaire-category-nav {
        #category-list {
            li {
                width: 100%;
            }
        }
    }
}

@media only screen and (min-width: $bootstrap-md) {
    #questionnaire-category-nav {
        margin: {
            top: pxToRem(-$mainTopPadding);
        }
        
        #category-list {
            li {
                display: inline-block;
            }
        }
    }
}
html {
    font-size: 100%;
    //@include hyphens(auto);
    word-wrap: break-word;
    overflow-wrap: break-word;
    min-height: 100%;
    width: 100%;
    height:100%;
    font-family: $font-family;
}

body {
    font: {
        family: $font-family;
        size: 1em;
        weight: $fontWeightLight;
    }
    width: 100%;
    position: relative;
    min-height: 100%;
    padding: {
        top: pxToRem($headerBottomHeight + $mainTopPadding);
    }
}

h1 {
    font-size: 2.2rem;
    font-weight: 700;
}

h2 {
    font-size: 1.5rem;
    font-weight: $fontWeightMedium;
}

h3 {
    font-size: 1.1rem;
    font-weight: $fontWeightMedium;
}

a {
    outline: none;
    color: inherit;
    
    &:not([href]), &[role="button"] {
        &:hover{
            text-decoration: none;
        }
    }
}

img {
    border: none;
    max-width: 100%;
    max-height: 100%;
    
    &:not([src]) {
        display: none;
    }
}

label {
    &[for] {
        cursor: pointer;
    }
}


[role="button"] {
    cursor: pointer;
}

// Keep the footer at the bottom
body {
    display: flex;
    flex-direction: column;
    height: 0;

    .content {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
    }
    
    .content-header {
        padding: {
            bottom: pxToRem($mainTopPadding);
        }
        
        .row {
            align-items: center;
        }
        
        .form-group, h1 {
            margin: {
                bottom: 0;
            }
        }
    }

    footer {
        flex-shrink: 0;
    }
}

.back-to-top {
    display: inline-block;
}

.footer-logo {
    width: pxToRem(85);
    height: pxToRem(85);
}

.help-block, .error-summary {
    color: red;
    font: {
        weight: $fontWeightBold;
    }
}

.hidden {
    display: none;
}

.invisible {
    opacity: 0;
}

.col-shrink {
    flex-shrink: 1;
    flex-grow: 0;
}

.alert-container {
    margin-bottom: 20px;

    .alert {
        opacity: 1;
    }
}

.color-draft {
    color: $ci_yellow;
}

.color-published {
    color: $ci_green;
}

@media only screen and (min-width: $bootstrap-md) {
    body {
        padding-top: pxToRem($headerHeight + $mainTopPadding);
    }
}

//WFL Placeholder mixin
//===============================
// @version 1.0.0
//

@mixin wfl-placeholder($browserDefault: false) {
    // Chrome, Safari, Opera
    &::-webkit-input-placeholder {
        @content;
    }

    // IE
    &:-ms-input-placeholder {
        @content;
    }

    // Edge
    &::-ms-input-placeholder {
        @content;
    }

    // Firefox <= 18
    &:-moz-placeholder {
        // at default Firefox set an opacity of placeholder
        @if $browserDefault == false {
            opacity: 1;
        }

        @content;
    }

    // Firefox > 18
    &::-moz-placeholder {
        // at default Firefox set an opacity of placeholder
        @if $browserDefault == false {
            opacity: 1;
        }

        @content;
    }

    &::placeholder {
        @content;
    }
}
.site-table {
    .table-actions--top {
        display: flex;
    }
    
    h1 {
        flex: 1 0 auto;
        
    }
    
    .table-actions--button {
        flex: 0 1 auto;
        
        & + .table-actions--button {
            margin: {
                left: pxToRem(30);
            }
        }
    }
    
    .user-icon {
        font: {
            size: pxToRem(20);
        }
    }
}

@media only screen and (max-width: $bootstrap-sm-max) {
    .site-table {
        .table-actions--top {
            flex-wrap: wrap;
        }
    
        tr {
            .td--questionnaire {
                flex-basis: pxToRem(210);
                max-width: pxToRem(210);
                width: pxToRem(210);
            }
        
            .td--employee {
                flex-basis: pxToRem(250);
                width: pxToRem(250);
            }
        
            .td--assignedAt, .td--updatedAt, .td--due {
                flex-basis: pxToRem(140);
                max-width: pxToRem(140);
                width: pxToRem(140);
            }
        
            .td--updatedAt {
            }
        
            .td--due {
            }
        }
    }
}
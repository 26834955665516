//WFL Checkbox mixin
//===============================
// @version 1.1.2
//
//The mixin provides you a cross-browser compatible method to add an universal styling of checkboxes.
//
//An element with class "wfl-check" is optional. Instead of the prepared css-check you can use an font-awesome icon
//or bootstrap glyphicon or something similar.
//
//Depending on the width and height of the checkbox and the used check-icon, you may to add a bottom value to the check-icon
//######Example:
//<div class="wfl-checkbox">
//    <input type="checkbox" />
//    <div><span class="wfl-check"></span></div>
//</div>
//
//<style>
//    .wfl-checkbox {
//        @include wfl-checkbox(30px, green);
//    }
//</style>
//
//$param: {String} size: height and width of visible checkbox (Default: 30px)
//$param: {String} border: border-color of visible checkbox (Default: grey)
//$param: {String} background: background of visible checkbox (Default: white)
//$param: {String} iconColor: color of check-symbol
//$param: {String} checkClass: class of element which should be visible if checkbox is checked. use wfl-check if you want
// to use the prepared check sign. But you can also use an font awesome icon or bootstrap glyphicon or something else.
// Just give the class name of it so that it can be invisible if checkbox is not checked. (Default: wfl-check)

@mixin wfl-checkbox($size: 40px, $border: grey, $background: white, $iconColor: green, $checkClass: wfl-check, $labelMargin: 10px) {
    // width and height of wfl-check
    $checkWidth: $size * 0.4;
    $checkHeight: $size * 0.7;
    
    display: inline-block;
    position: relative;
    height: $size;
    width: $size;
    
    input {
        &[type="checkbox"], &[type="radio"] {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            height: $size;
            width: $size;
            z-index: 2;
            margin: 0;
            cursor: pointer;
            
            & + div {
                position: relative;
                z-index: 1;
                border: 1px solid $border;
                display: inline-block;
                height: $size;
                width: $size;
                text-align: center;
                vertical-align: middle;
                background: $background;
                
                .wfl-check {
                    position: relative;
                }
                
                .#{$checkClass} {
                    pointer-events: none;
                    color: $iconColor;
                }
            }
        }
        
        &[type="checkbox"] {
            & + div {
                // {Optional} prepared css check icon.
                .wfl-check {
                    display: inline-block;
                    width: $checkWidth;
                    height: $checkHeight;
                    border: {
                        bottom: 1px solid $iconColor;
                        right: 1px solid $iconColor;
                    }
                    @include rotate(45deg);
                }
            }
            
            &:not(:checked) {
                & + div {
                    .#{$checkClass} {
                        color: transparent;
                    }
                }
            }
        }
        
        &[type="radio"] {
            & + div {
                border: {
                    radius: 50%;
                }
                
                @if $checkClass == wfl-check {
                    padding: {
                        top: round($size / 4 - 2);
                        bottom: round($size / 4 - 2);
                    }
                }
                
                .wfl-check {
                    display: block;
                    width: $size / 2;
                    height: $size / 2;
                    background: {
                        color: $iconColor;
                    }
                    border: {
                        radius: 50%;
                    }
                    left: 50%;
                    margin: {
                        left: -#{floor($size / 4)};
                    }
                }
            }
            
            &:not(:checked) {
                & + div {
                    .#{$checkClass} {
                        opacity: 0;
                    }
                }
            }
        }
    }
    
    & + label {
        margin: {
            left: $labelMargin;
        }
        cursor: pointer;
    }
    
    // unfortunately you cannot select a previous element :(
    label + & {
        margin: {
            left: $labelMargin;
        }
    }
}
$containerDesktopWidth: 920;
$defaultLargerFontSizePx: 24;
$defaultLargerFontSize: pxToRem($defaultLargerFontSizePx);
$defaultFontSize: pxToRem(18);
$maxContainerPadding: 30;

/******************************/
/* welcome
/******************************/
#eLearning--top {
    z-index: 10;
    background: {
        repeat: no-repeat;
        position: 110% top;
    }
    
    @include wfl-device-pixel-ratio(1.5, max) {
        background: {
            image: url("../images/bg_logo_gr.png");
        }
    }
    
    @include wfl-device-pixel-ratio(1.5001, min) {
        background: {
            image: url("../images/bg_logo_gr@2x.png");
        }
    }
}

#eLearning--title {
    font: {
        size: pxToRem(30);
        weight: $fontWeightLight;
    }
    margin: {
        bottom: pxToRem(40);
    }
}

#eLearning--welcome {
    padding: {
        top: pxToRem(60);
        bottom: pxToRem(100);
    }
    text-align: center;
    background: {
        color: transparentize(#F5F3EC, 0.6);
    }
    
    p {
        font: {
            size: $defaultLargerFontSize;
        }
        line-height: pxToRem(40);
    }
}

#eLearning--anchors {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    margin: {
        bottom: pxToRem(60);
    }
}

.eLearning--anchor-item {
    flex-shrink: 0;
    flex-grow: 1;
    border: pxToRem(1) solid grey;
    background: {
        color: $white;
    }
    color: #888;
    
    &:hover {
        background: {
            color: $ci_yellow;
        }
        border: {
            color: $ci_yellow;
        }
        text-decoration: none;
        color: $black;
    }
}

/******************************/
/* privacy
/******************************/
#eLearning--privacy-container {
    background: {
        color: black;
    }
    color: $white;
    padding: {
        top: pxToRem(90);
    }
    z-index: 9;
    background: {
        size: 100% pxToRem(740);
        repeat: no-repeat;
        color: #2D2221;
    }
    
    @include wfl-device-pixel-ratio(1.5, max) {
        background: {
            image: url("../images/eLearning/man_top.png");
        }
    }
    
    @include wfl-device-pixel-ratio(1.5001, min) {
        background: {
            image: url("../images/eLearning/man_top@2x.png");
        }
    }
    
    & > p {
        font: {
            size: $defaultLargerFontSize;
        }
        line-height: pxToRem(40);
        text-align: center;
        
        b {
            font: {
                weight: $fontWeightMedium;
            }
            line-height: pxToRem(30);
        }
    }
}

#eLearning--privacy-icon {
    font: {
        size: pxToRem(55);
    }
    margin: {
        left: auto;
        right: auto;
        bottom: pxToRem(15);
    }
}

#eLearning--privacy {
    background: {
        color: $ci_yellow;
    }
    color: $black;
    margin: {
        top: pxToRem(90);
    }
    
    .eLearning--article {
        margin: {
            top: pxToRem(20);
        }
        
        h3 {
            font: {
                size: $defaultFontSize;
                weight: $fontWeightMedium;
            }
            line-height: 1;
            margin: {
                bottom: pxToRem(20)
            }
        }
    }
    
    p {
        font: {
            size: $defaultFontSize;
        }
        line-height: pxToRem(28);
    }
}

/******************************/
/* personal data
/******************************/
#eLearning--personalData-container {
    background: {
        color: darkred;
    }
    z-index: 8;
    background: {
        size: 100% pxToRem(1820);
        repeat: no-repeat;
        color: $black;
    }
    
    @include wfl-device-pixel-ratio(1.5, max) {
        background: {
            image: url("../images/eLearning/red_city.png");
        }
    }
    
    @include wfl-device-pixel-ratio(1.5001, min) {
        background: {
            image: url("../images/eLearning/red_city@2x.png");
        }
    }
    
    .vimeo {
        margin: {
            top: pxToRem(25);
        }
    }
}

#eLearning--personalData {
    background: {
        color: $ci_red;
    }
}

/******************************/
/* processing
/******************************/
#eLearning--processing-container {
    background: {
        color: black;
    }
    z-index: 7;
    background: {
        size: 100% pxToRem(1150);
        repeat: no-repeat;
        color: #3F3F3F;
    }
    
    @include wfl-device-pixel-ratio(1.5, max) {
        background: {
            image: url("../images/eLearning/man_top2.png");
        }
    }
    
    @include wfl-device-pixel-ratio(1.5001, min) {
        background: {
            image: url("../images/eLearning/man_top2@2x.jpg");
        }
    }
}

#eLearning--processing {
    background: {
        color: $white;
    }
    padding: {
        bottom: pxToRem(28);
    }
    
    h3 {
        color: $ci_red;
        margin: {
            bottom: pxToRem(30);
        }
    }
    
    p {
        &:first-of-type {
            margin: {
                bottom: pxToRem(35);
            }
        }
        
        &:last-of-type {
            margin: {
                bottom: pxToRem(30);
            }
        }
    }
}

#eLearning--processing-additional-container {
    background: {
        color: #3F3F3F;
    }
    z-index: 6;
}

#eLearning--processing-additional {
    header {
        margin: {
            bottom: pxToRem(25);
        }
        
        h3 {
            color: $ci_red;
        }
        
        & + p {
            color: $white;
            max-width: pxToRem(730);
            margin: {
                bottom: pxToRem(30);
            }
        }
    }
}

$columnGap: pxToRem(30);
#eLearning--basisPriciples {
    column-count: 3;
    column-gap: $columnGap;
    column-width: pxToRem(350);
    max-width: 100%;
}

.eLearning--basic-principle {
    background: {
        color: $white;
    }
    margin: {
        bottom: $columnGap;
    }
    $padding: pxToRem(35);
    padding: {
        top: pxToRem(40);
        left: $padding;
        right: $padding;
        bottom: $padding;
    }
    display: inline-block;
    
    h3 {
        font: {
            size: pxToRem(16);
        }
        margin: {
            bottom: pxToRem(30);
        }
        line-height: 1;
    }
    
    p {
        margin: {
            bottom: 0;
        }
        font: {
            size: pxToRem(14);
        }
    }
}

#eLearning--processing-last-container {
    z-index: 5;
    background: {
        size: 100% pxToRem(2200);
        repeat: no-repeat;
        color: #3F3F3F;
    }
    
    @include wfl-device-pixel-ratio(1.5, max) {
        background: {
            image: url("../images/eLearning/hafen_city.png");
        }
    }
    
    @include wfl-device-pixel-ratio(1.5001, min) {
        background: {
            image: url("../images/eLearning/hafen_city@2x.png");
        }
    }
}

#eLearning--processing-last {
    background: {
        color: $ci_yellow;
    }
    margin: {
        top: pxToRem(15);
    }
}

/******************************/
/* legal basics
/******************************/
#eLearning--legalBasics-container {
    z-index: 4;
    background: {
        size: 100% pxToRem(3150);
        repeat: no-repeat;
        color: $ci_red;
    }
    
    @include wfl-device-pixel-ratio(1.5, max) {
        background: {
            image: url("../images/eLearning/red_ordner.png");
        }
    }
    
    @include wfl-device-pixel-ratio(1.5001, min) {
        background: {
            image: url("../images/eLearning/red_ordner@2x.png");
        }
    }
}

#eLearning--legalBasics {
    background: {
        color: $white;
    }
    
    h3 {
        color: $ci_red;
        margin: {
            top: pxToRem(45);
            bottom: pxToRem(30);
        }
        font: {
            size: $defaultFontSize;
        }
    }
}

#eLearning--legalBasics-video {
    background: {
        color: $ci_red;
    }
}

/******************************/
/* third parties
/******************************/
#eLearning--thirdParties-container {
    z-index: 3;
    background: {
        color: #211B00;
    };
}

#eLearning--thirdParties {

}

.thirdParties-container {
    background: {
        color: $white;
    }
    
    h3 {
        color: $ci_red;
    }
    
    &.concept {
        padding: {
            bottom: pxToRem(15);
        }
        
        h3 {
            font: {
                size: $defaultFontSize;
            }
            margin: {
                bottom: pxToRem(40);
            }
        }
    }
    
    &.transfer {
        padding: {
            bottom: pxToRem(30);
        }
        
        h3 {
            margin: {
                bottom: pxToRem(30);
            }
        }
    }
    
    .summary {
        h3 {
            margin: {
                bottom: pxToRem(20);
            }
        }
        
        p {
            font: {
                size: $defaultLargerFontSize;
            }
        }
    }
    
    .column {
        p {
            display: inline-block;
        }
    }
    
    .vimeo {
        margin: {
            top: pxToRem(35);
        }
    }
}

.redBoxes-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
    .redbox-placeholder, .redbox {
        flex: 0 1 pxToRem(225);
        height: pxToRem(55);
        margin: {
            bottom: pxToRem(30);
        }
    }
    
    .redbox {
        background: {
            color: red;
        }
        color: $white;
        border: pxToRem(1) solid $black;
        text-align: center;
        display: flex;
        align-items: center;
        
        .text {
            flex: 1 0 100%;
        }
    }
}

/******************************/
/* rights
/******************************/
#eLearning--rights-container {
    z-index: 2;
    background: {
        size: cover;
        repeat: no-repeat;
        color: $ci_background_yellow;
    }
    
    @include wfl-device-pixel-ratio(1.5, max) {
        background: {
            image: url("../images/eLearning/hand.png");
        }
    }
    
    @include wfl-device-pixel-ratio(1.5001, min) {
        background: {
            image: url("../images/eLearning/hand@2x.png");
        }
    }
}

#eLearning--rights {
    background: {
        color: $ci_yellow;
    }
}

/******************************/
/* overall / helpers
/******************************/
.eLearning--max-container {
    width: 100%;
    padding: {
        left: pxToRem($maxContainerPadding);
        right: pxToRem($maxContainerPadding);
    }
    margin: {
        left: auto;
        right: auto;
    }
}

.eLearning-container {
    p {
        font: {
            size: $defaultFontSize;
            weight: $fontWeightLight;
        }
        line-height: pxToRem(28);
    }
}

.column {
    column: {
        count: 2;
        gap: pxToRem(30);
    }
    
    p {
        //display: inline-block;
    }
}

.eLearning--section-header {
    margin: {
        bottom: pxToRem(35);
    }
    padding: {
        top: pxToRem(60);
    }
    
    .circle {
        $size: 155;
        $borderSize: 3;
        display: block;
        width: pxToRem($size);
        height: pxToRem($size);
        margin: {
            left: auto;
            right: auto;
            bottom: pxToRem(45);
        }
        border: pxToRem($borderSize) solid $black;
        border-radius: 50%;
        text-align: center;
        line-height: pxToRem($size - ($borderSize * 2));
        font: {
            size: pxToRem(100);
            weight: $fontWeightLight;
        }
    
        .eLearning-colored-section.color-red & {
            color: $white;
            border: {
                color: $white;
            }
        }
    }
    
    &.small {
        padding: {
            top: pxToRem(40);
        }
        margin: {
            bottom: pxToRem(40);
        }
    }
    
    &.x-small {
        padding: {
            top: pxToRem(30);
        }
        margin: {
            bottom: pxToRem(25);
        }
    }
}

.eLearning--section-title {
    font: {
        size: $defaultLargerFontSize;
        weight: $fontWeightMedium;
    }
    margin: {
        bottom: 0;
    }
    text-align: center;
    
    .eLearning-colored-section.color-red & {
        color: $white;
    }
    
    .eLearning--section-header.small & {
        font: {
            size: $defaultFontSize;
        }
    }
    
    .eLearning--section-header.x-small & {
        font: {
            size: $defaultFontSize;
        }
    }
}

.vimeo {
    position: relative;
    padding: {
        top: 56.25%;
    }
    margin: {
        bottom: pxToRem(30);
    }
    
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    
    // margin-bottom large
    &.mb-large {
        margin: {
            bottom: pxToRem(40);
        }
    }
}

.eLearning--summary {
    h3 {
        color: $ci_red;
        font: {
            size: $defaultFontSize;
            weight: $fontWeightMedium;
        }
        line-height: pxToRem(21);
        margin: {
            bottom: 0;
        }
    
        .eLearning-colored-section.color-red & {
            color: $white;
        }
        
        &::after {
            content: ":";
        }
    }
    
    p {
        font: {
            size: $defaultLargerFontSize;
        }
        line-height: 1;
        color: $white;
        margin: {
            top: pxToRem(15);
            bottom: pxToRem(10);
        }
    }
    
    ul {
        margin: {
            bottom: 0;
        }
        padding: {
            left: 0;
        }
        list-style: none;
        
        li {
            $iconSize: 22;
            $iconMargin: 16;
            $fontSizePx: $defaultLargerFontSizePx;
            $padding: pxToRem($fontSizePx / 2);
            font: {
                size: pxToRem($fontSizePx);
                weight: $fontWeightRegular;
            }
            position: relative;
            padding: {
                top: $padding;
                bottom: $padding;
                left: pxToRem($iconSize + $iconMargin);
            }
            display: flex;
            align-items: center;
            line-height: 1;
            
            &::before {
                content: "";
                display: inline-block;
                position: absolute;
                left: 0;
                top: 0;
                height: pxToRem($defaultLargerFontSizePx * 2);
                width: pxToRem($iconSize);
                background: {
                    image: url("../images/eLearning/check_circle.svg");
                    position: center;
                    repeat: no-repeat;
                    size: pxToRem($iconSize);
                }
                vertical-align: middle;
            }
        }
    }
    
    &.in-between-summary {
        font: {
            size: pxToRem(22);
        }
        padding: pxToRem(30) pxToRem(15);
        background: {
            color: $ci_red;
        }
        
        ul {
            color: $white;
        }
    }
}

.eLearning-colored-section {
    padding: {
        bottom: pxToRem(68);
    }
}

@media (max-width: $bootstrap-sm-max) {
    .eLearning--anchor-item {
        flex-basis: 100%;
        height: pxToRem(40);
        margin: {
            left: pxToRem(-15);
            right: pxToRem(-15);
        }
        padding: {
            left: pxToRem(15);
            right: pxToRem(15);
        }
        display: flex;
        align-items: center;
        line-height: 1.1;
        
        .item-counter {
            margin: {
                right: pxToRem(15);
            }
        }
    }
}

@media (min-width: $bootstrap-md) {
    .eLearning--anchor-item {
        flex-basis: pxToRem(160);
        height: pxToRem(120);
        text-align: center;
        margin: {
            right: pxToRem(30);
        }
    
        .item-counter {
            display: block;
            font: {
                size: pxToRem(48);
                weight: $fontWeightLight;
            }
            line-height: pxToRem(54);
            margin: {
                top: pxToRem(6);
            }
        }
    
        .item-text {
            font: {
                size: pxToRem(14);
                weight: $fontWeightRegular;
            }
            line-height: $defaultFontSize;
        }
    
        &:last-of-type {
            margin: {
                right: 0;
            }
        }
    }
    
    .eLearning-container {
        .container {
            max-width: pxToRem($containerDesktopWidth);
        }
        
        p.container {
            max-width: pxToRem($containerDesktopWidth + 30);
        }
    }
}

@media (min-width: $bootstrap-lg) {
    .eLearning-container {
        .container {
            max-width: pxToRem($containerDesktopWidth);
        }
    
        p.container {
            max-width: pxToRem($containerDesktopWidth + 30);
        }
    }
    
    .eLearning-colored-section {
        $desktopPadding: pxToRem(95);
        padding: {
            left: $desktopPadding;
            right: $desktopPadding;
        }
    }
}

@media (min-width: $bootstrap-xl) {
    #eLearning--privacy-container {
        height: pxToRem(1920);
    }
    
    #eLearning--personalData-container {
        height: pxToRem(2390);
    }
    
    #eLearning--personalData {
        margin: {
            top: pxToRem(1080);
        }
    }
    
    #eLearning--processing-container {
        height: pxToRem(1150);
    }
    
    #eLearning--processing {
        margin: {
            top: pxToRem(660);
        }
    }
    
    #eLearning--processing-additional-container {
        height: pxToRem(900);
    }
    
    #eLearning--processing-additional {
        margin: {
            top: pxToRem(620);
        }
    }
    
    #eLearning--processing-last-container {
        height: pxToRem(2200);
    }
    
    #eLearning--processing-last {
        margin: {
            top: pxToRem(1033);
        }
        z-index: 4;
    }
    
    #eLearning--legalBasics-container {
        z-index: 5;
        height: pxToRem(3150);
    }
    
    #eLearning--legalBasics {
        margin: {
            top: pxToRem(-170);
        }
    }
    
    #eLearning--thirdParties-container {
        height: pxToRem(1790);
        z-index: 6;
    }
    
    #eLearning--thirdParties {
        margin: {
            top: pxToRem(-340);
        }
    }
    
    #eLearning--rights-container {
        height: pxToRem(1820);
        margin: {
            bottom: pxToRem(770);
        }
    }
    
    #eLearning--rights {
        margin: {
            top: pxToRem(840);
        }
    }
    
    .eLearning--max-container {
        width: pxToRem(1300 + (2 * $maxContainerPadding));
    }
}


/* Elearning Styles */
.elearning-admin-buttons{
    background-color: #f3cf33;
    padding: 10px;
    }
.elearning-update-button{
	padding:0.25rem 1rem;
}
.assigne-users, .users-statistics{
	background: #00B233;
	color: #fff;
}
.due-date-old{
	background: #D03345;
}
.users-statistics-fail{
	background: #D52B10;
	color: #fff;
}
.users-statistics-empty{
	background: #F0C400;
	color: #000;
}
.users-statistics-button{
	padding: 9px 9px 11px 11px;
}
.status-statik-pass{
	color: #00B233;
}
.status-statik-fail{
	color: #AD311D;
}
.elearning-status-finished{
	color: #00B233;
}
.elearning-status-in-work{
	color: #F0C400;
}
.elearning-question-card{
	float: right;
	width: 100%;
}
.elearning-question-card-options{
	float: right;
	display: inline-flex;
	border-top: 1px solid #F0C400;
	border-right: 1px solid #F0C400;
}
.questions .card-option.elearning-card-edit {
    background-color: #FFF;
    width: 3.125rem; }
.questions .card-option.elearning-card-edit:hover {
    color: #F0C400 !important; }
.questions .card-option.elearning-card-delete {
    background-color: #CC0018;
    border-top-width: 0.0625rem;
    border-top-style: solid;
    border-color: #CC0018;
    color: #FFF;
    width: 3.125rem; }
.questions .elearning-card-header {
    background-color: #F0C400;
    border-top: 1px solid #F0C400;
    border-left: 1px solid #F0C400;
    border-bottom: 1px solid #F0C400;
    border-right: 1px solid #F0C400;
    display: flex;
    align-items: stretch; }
.questions .elearning-card-field {
   float: left;
   width: calc(100%);
   min-height: 6.25rem;
   background-color: #F0C400;
   padding: 1.05rem;}
.questions .sub-question-admin .elearning-card-header {
    background-color: #dc5342;
    border-top: 1px solid #dc5342;
    border-left: 1px solid #dc5342;
    border-bottom: 1px solid #dc5342;
    border-right: 1px solid #dc5342;
    display: flex;
    align-items: stretch; }
.questions .sub-question-admin .elearning-card-field {
   float: left;
   width: calc(100%);
   min-height: 6.25rem;
   background-color: #dc5342;
   padding: 1.05rem;}
.questions .question-card.sub-question-user{
   margin-top:0;
}
.questions .elearning-card-field .error-container {
     color: #CC0018; }
.questions .elearning-card-field .file-entry {
     padding: 10px 0; }
.elearning-answer-group-container {
  max-width: 100%;
  column-gap: 1.875rem;
  -moz-column-count: 1;
  -webkit-column-count: 1;
  background-color: #F6EECB;
  column-count: 1;
  padding: 20px;
  column-width: 11.25rem; }
.elearning-answer-group-container .dropdown, .elearning-answer-group-container .text {
    display: inline-block; }
.elearning-answer-row {
  -webkit-column-break-inside: avoid;
  /* Chrome, Safari, Opera */
  page-break-inside: avoid;
  /* Firefox */
  break-inside: avoid;
  /* IE 10+ */
  padding: 1px 1px 1.3rem;
  display: flex;
  align-items: center; }
.elearning-answer-row label {
  -ms-word-break: break-word;
  word-break: break-word; }
#schulung-view {
  z-index: 10;
  background-repeat: no-repeat;
  background-position: 110% top; }
  @media only screen and (-webkit-max-device-pixel-ratio: 1.5), (max-resolution: 144dpi), (max-resolution: 1.5dppx) {
    #schulung-top {
      background-image: url("../images/bg_logo_gr.png"); } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.5001), (min-resolution: 144.0096dpi), (min-resolution: 1.5001dppx) {
    #schulung-top {
      background-image: url("../images/bg_logo_gr@2x.png"); } }

#schulung-title {
  font-size: 1.875rem;
  font-weight: 300;
  margin-bottom: 2.5rem; }
#eLearning-intro {
  padding-top: 3.75rem;
  padding-bottom: 6.25rem;
  background-color: rgba(245, 243, 236, 0.4); }
#eLearning-intro p {
    font-size: 1rem;
    font-weight: 300; }
#eLearning-question-section{
	background-color: #F0C400;
	color: #000;
	}
.wissen-frage{
	font-size: 1.25rem;
	padding-bottom: 1.25rem;
}
.user-answer-help{
	color:red;
}
.save-answer-div{
	text-align: end;
}
.save-answer-button{
	background: #00B233;
	color: #fff;
	padding: 0.3rem 1.5rem;
}
.questions .question-card.question-finished-close {
    cursor: pointer; }
.questions .question-card.question-finished .elearning-card-field,
.questions .elearning-card-header.question-finished
 {
      display: none; }
#questionForm .dropdown{
	width: 100%;
}
#schulung-intro p{
	font-size: 1.2rem;
	font-weight: 300;
	line-height: 2.05rem;
}
.can-view-user{
	text-align: right;
	text-align: -webkit-right;
	text-align: -moz-right;
	text-align: -o-right;
	text-align: -ms-right;
}
.can-view-user a{
	display: inherit;
	padding:2px 4px;
}
.result-pass{
	color: #00B233;
}
.result-fail{
	color: #D52B10;
}
.e-question-finished{
	display:none;
}
.progress-bar-success{
	background-color: #00B233;
}
.progress-bar-info{
	background-color: #F0C400;
	color: #000;
}
.wrong-field{
	color: #D52B10;
	font-size: 1.3rem;
}
.correct-field{
	color: #00B233;
	font-size: 1.3rem;
}
.progress{
	height: 1.7rem;
}
.progress-title{
	color: #F00800;
	font-weight: normal;
	font-size: 1.2rem;
}
.try-again-button{
	background: red;
}
.wrong-box{
	width: 85px;
}
.big-check{
	font-size: 1.3rem;
}
.big-empty-square{
	font-size: 1.2rem;
	border-style: solid;
	color: #fff;
	border-color: #000;
	border-width: 1px;
}
.red{
	color: #D52B10;
}
.green{
	color: #00B233;
}
.help-text{
	font-size: 1rem;
	font-style: italic;
}
.wrong-answer-text{
	font-size: 1.2rem;
}
.info-icon{
	font-size: 1.3rem;
	color: #F0C400;
}
.info-row{
	margin-left: 25px;
}
.due-date-coming{
	color: #00B233;
}
.due-date-passed{
	color: #D52B10;
}
.button-due-date-passed{
	background-color: #D52B10;
	color: #fff;
}
.elearningForm .question-form .answer-fields .form-control {
    width: 36.5rem;
    display: inline-block; }
.table tbody td.elearning-user-buttons a {
	display: inherit;}
	.table tbody td.elearning-admin-buttons a {
    display: inherit;}

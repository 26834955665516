.table-legend {
    caption-side: top;
    color: $black;
    font: {
        size: pxToRem(12);
        weight: $fontWeightRegular;
    }
    margin: {
        top: pxToRem(15);
        bottom: pxToRem(8)
    }
    
    span {
        display: inline-block;
        vertical-align: middle;
        
        & + span {
            margin: {
                left: pxToRem(8);
            }
        }
    }
    
    .fas {
        font: {
            size: 1rem;
        }
    }
    
    .status--outstanding {
        color: $ci_red;
    }
    
    .status--invited, .status--in-work {
        color: $ci_yellow;
    }
    
    .status--active, .status--finished {
        color: $ci_green;
    }
}

.table {
    thead {
        a {
            @include fa-icon;
            font-weight: 900;
        
            &::after {
                @extend .fas;
                content: fa-content($fa-var-sort);
                margin: {
                    left: pxToRem(10);
                }
            }
        
            &.asc {
                &::after {
                    content: fa-content($fa-var-sort-up);
                }
            }
        
            &.desc {
                &::after {
                    content: fa-content($fa-var-sort-down);
                }
            }
        
            &:hover {
                text-decoration: none;
            }
        }
    }
    
    tbody {
        tr {
            &.status--active {
                background: {
                    color: $ci_background_gray;
                }
                
                .row-status {
                    color: $ci_green;
                }
                
                .row-action {
                    &:hover {
                        color: $ci_green;
                    }
                }
            }
            
            &.status--finished {
                background: {
                    color: $ci_background_green;
                }
    
                .row-status {
                    color: $ci_green;
                }
    
                .row-action {
                    &:hover {
                        color: $ci_green;
                    }
                }
            }
            
            &.status--invited, &.status--in-work {
                background: {
                    color: $ci_background_yellow;
                }
                
                .row-status {
                    color: $ci_yellow;
                }
                
                .row-action {
                    &:hover {
                        color: $ci_yellow;
                    }
                }
            }
            
            &.status--outstanding {
                background: {
                    color: $ci_background_gray;
                }
    
                .row-status {
                    color: $ci_red;
                }
    
                .row-action {
                    &:hover {
                        color: $ci_red;
                    }
                }
            }
            
            &:last-of-type {
                td {
                    border-bottom: pxToRem(1) solid $black;
                }
            }
        }
        
        .td--status {
            text-align: center;
        }
        
        .td--user {
            span {
                display: inline-block;
                vertical-align: middle;
                
                & + span {
                    margin: {
                        left: pxToRem(20);
                    }
                }
            }
        }
    
        td.td--actions {
            padding: 0;
        
            a {
                display: inline-block;
                padding: 13px 15px;
            }
        }
    }

    //Custom table for overriding border values and colors
    &.table-wfl {

        //Arrow icon in questionare table - user column
        .row-user {
            &.fa-arrow-up {
                transform: rotate(45deg);

                &:hover {
                    color: $ci-green;
                }
            }
        }

        //"Spaces"
        td {
            border: {
                bottom: 2px solid $white;
            }
        }

        thead {
            th {
                border: {
                    top: 1px solid $black;
                    bottom: 1px solid $black;
                }
                white-space: nowrap;
            }

            th {
                font-size: pxToRem(13);
                line-height: pxToRem(25);
            }
        }

        tbody {
            font-weight: 400;

            //Icon only columns
            .td--status, .td--actions {
                font: {
                    size: pxToRem(16);
                }
            }

            td {
                vertical-align: middle;
                font: {
                    size: pxToRem(12);
                }
                
                &.td--user {
                    white-space: nowrap;
                }
                
                &.td--questionnaire {
                    font-size: pxToRem(13);
                }
            }
        }
    }
}

.table.borderless tbody tr td,
.table.borderless tbody tr th {
    border: none;
}

.pagination {
    justify-content: flex-end;
    margin: {
        bottom: pxToRem(45);
    }
    
    li {
        $border: 1px solid $tablePaginationBorder;
        $size: pxToRem(30);
        width: $size;
        height: $size;
        border: {
            top: $border;
            bottom: $border;
            left: $border;
        }
        text-align: center;
        
        a {
            display: block;
            height: 100%;
            line-height: pxToRem(28);
            color: $black;
            font: {
                size: pxToRem(13);
                weight: 900;
            }
        }
        
        &.prev, &.next {
            background: {
                color: $tablePaginationBorder;
            }
            
            .fas {
                line-height: pxToRem(28);
            }
    
            &:not(.disabled) {
                .fas {
                    font: {
                        size: pxToRem(13);
                    }
                }
            }
            
            &.disabled {
                .fas {
                    font: {
                        size: pxToRem(10);
                    }
                }
            }
        }
        
        &.active {
            background: {
                color: $black;
            }
            
            a {
                color: $white;
            }
        }
        
        &:last-of-type {
            border: {
                right: $border;
            }
        }
    }
}

@media only screen and (max-width: $bootstrap-sm-max) {
    [data-pjax-container] {
        margin: {
            left: -15px;
            right: -15px;
        }
    }
    
    .table {
        &.table-wfl {
            display: block;
            position: relative;
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0;
            overflow-x: scroll;
    
            tr {
                display: flex;
    
                td, th {
                    flex-grow: 1;
                    order: 2;
                    @include wfl-text-overflow();
                }
    
                .td--status {
                    display: none;
                }
    
                .td--user, .td--email, .td--company {
                    $tdWith: pxToRem(210);
                    flex-basis: $tdWith;
                    max-width: $tdWith;
                    width: $tdWith;
                }
                
                .td--phone, .td--branch, .td--address {
                    $tdWith: pxToRem(140);
                    flex-basis: $tdWith;
                    max-width: $tdWith;
                    width: $tdWith;
                }
                
                .td--phone, .td--position, .td--role {
                    $tdWith: pxToRem(120);
                    flex-basis: $tdWith;
                    max-width: $tdWith;
                    width: $tdWith;
                }
    
                .td--actions {
                    order: 1;
                    flex-basis: pxToRem(36);
                }
            }
            
            tbody {
                tr {
                    .td--actions {
                        flex-grow: 0;
                        text-overflow: unset;
                        margin: {
                            left: pxToRem(-50);
                        }
                        @include transition(margin-left 300ms);
                        background: {
                            color: $ci_gray;
                        }
                
                        .responsive-status {
                            /*padding: {
                                left: pxToRem(9);
                                right: pxToRem(9);
                            }*/
                        }
                
                        a {
                            color: $white;
                        }
                
                        &.can-delete {
                            flex-basis: pxToRem(134);
                            margin: {
                                left: pxToRem(-98);
                            }
                        }
                
                        &.can-view {
                            flex-basis: pxToRem(134);
                            margin: {
                                left: pxToRem(-98);
                            }
                        }
                        
                        &.can-view-history {
                            flex-basis: pxToRem(185);
                            margin: {
                                left: pxToRem(-149);
                            }
                        }
                        
                        &.can-view-history.can-copy {
                            flex-basis: pxToRem(233);
                            margin: {
                                left: pxToRem(-197);
                            }
                        }
                
                        &.can-delete.can-view {
                            flex-basis: pxToRem(184);
                            margin: {
                                left: pxToRem(-145);
                            }
                        }
                
                        &.open {
                            margin: {
                                left: 0!important;
                            }
                        }
                    }
            
                    &.status--finished, &.status--active {
                        .td--actions {
                            background: {
                                color: $ci_green;
                            }
                        }
                     
                        .row-action {
                            &:hover {
                                color: $black;
                            }
                        }
                    }
            
                    &.status--in-work, &.status--invited {
                        .td--actions {
                            background: {
                                color: $ci_yellow;
                            }
                        }
    
                        .row-action {
                            &:hover {
                                color: $black;
                            }
                        }
                    }
            
                    &.status--outstanding {
                        .td--actions {
                            background: {
                                color: $ci_red;
                            }
                        }
    
                        .row-action {
                            &:hover {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media all and (min-width: $bootstrap-md) {
    .table {
        tbody {
            td {
                &.td--actions {
                    a.responsive-status {
                        display: none;
                    }
                }
            }
        }
    }
}

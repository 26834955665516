/*
Column count mixin
=============================================
This mixin provides you a cross-browser compatible method to set the column count property.

Please refer to <http://caniuse.com/multicolumn> to see the browser support table of the feature.

######Example:
`.column-count([value]);`

`@param: {Number} count: The amount of columns you want to set (Default: auto)`
*/

@mixin column-count($count: auto) {
    -moz-column-count: $count;
    -webkit-column-count: $count;
    column-count: $count;
}

@mixin column-break-inside($type: avoid) {
    -webkit-column-break-inside: $type; /* Chrome, Safari, Opera */
    page-break-inside: $type; /* Firefox */
    break-inside: $type; /* IE 10+ */
}
$fontWeightLight: 300;
$fontWeightRegular: 400;
$fontWeightMedium: 500;
$fontWeightBold: 700;

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: $fontWeightBold;
    src: local('Ubuntu Bold'), local('Ubuntu-Bold'), url('../fonts/Ubuntu-Bold.woff2') format('woff2');
}

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: $fontWeightMedium;
    src: local('Ubuntu Medium'), local('Ubuntu-Medium'), url('../fonts/Ubuntu-Medium.woff2') format('woff2');
}

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: $fontWeightRegular;
    src: local('Ubuntu Regular'), local('Ubuntu-Regular'), url('../fonts/Ubuntu-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: $fontWeightLight;
    src: local('Ubuntu Light'), local('Ubuntu-Light'), url('../fonts/Ubuntu-Light.woff2') format('woff2');
}

$font-family: 'Ubuntu', sans-serif;
$fa-font-path: "../libs/fontawesome-5.15.3/webfonts";

.tooltip {
    .arrow {
    
    }
}

.tooltip-inner {
    color: $ci_yellow;
}


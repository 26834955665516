/*
Transition mixin
=========================================
The mixins provides you a cross-browser compatible method to set a transition.

Please refer to <http://caniuse.com/css-transitions> to see the browser support table of the feature.

######Example:
`@mixin transition([property], [duration-value], [easing]);`

`@param: {String} properties: The properites which should be animated (Default: all)`<br/>
`@param: {String} duration: Duration of the transition including the unit (Default: 0.3s)`<br/>
`@param: {String} easing: The easing which should be used for the transition (Default: ease-out)`
*/

@mixin transition($value: all 0.3s ease-out) {
    -webkit-transition: $value;
    -moz-transition: $value;
    -ms-transition: $value;
    -o-transition: $value;
    transition: $value;
}

@mixin transition-property ($value...) {
    -webkit-transition-property: $value;
    -moz-transition-property: $value;
    -ms-transition-property: $value;
    -o-transition-property: $value;
    transition-property: $value;
}

@mixin transition-duration ($value...) {
    -webkit-transition-duration: $value;
    -moz-transition-duration: $value;
    -ms-transition-duration: $value;
    -o-transition-duration: $value;
    transition-duration: $value;
}

@mixin transition-timing-function ($value...) {
    -webkit-transition-timing-function: $value;
    -moz-transition-timing-function: $value;
    -ms-transition-timing-function: $value;
    -o-transition-timing-function: $value;
    transition-timing-function: $value;
}

@mixin transition-delay ($value...) {
    -webkit-transition-delay: $value;
    -moz-transition-delay: $value;
    -ms-transition-delay: $value;
    -o-transition-delay: $value;
    transition-delay: $value;
}
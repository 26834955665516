/*
Clearfix mixin
========================================

Same as clearfix class from bootstrap

*/

@mixin clearfix() {
    &::before, &::after {
        content: "";
        display: table;
        clear: both;
    }
}
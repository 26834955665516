//WFL Text Overflow mixin
//===============================
// @version 1.0.0
//
//truncate long texts.
//
//$param: {String} textOverflow: kind of text-overflow (Default: ellipsis)

@mixin wfl-text-overflow($textOverflow: ellipsis) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: $textOverflow;
}
//WFL Required mixin
//===============================
// @version 1.0.0
//

@mixin wfl-required($showColon: true) {
    $colon: "";

    @if $showColon {
        $colon: ": ";
    }

    .required {
        label {
            &::after {
                content: "#{$colon}*";
            }
        }
    }
}
// CI
$ci_red: #CC0018;
$ci_dark_red: darken($ci_red, 7%);
$ci_yellow: #F0C400;
$ci_light_yellow: #FFF1BA;
$ci_dark_yellow: darken($ci_yellow, 7%);
$ci_green: #00B233;
$ci_light_green: #F1FAF3;
$ci_gray: #DADADA;

// CI-Background Colors
$ci_background_gray: #FAFAFA;
$ci_background_green: #F1FAF3;
$ci_background_yellow: #FDF8E4;
$ci_background_grey_light: #EEE;

// Border colors
$tablePaginationBorder: #F5F5F5;


// Font colors
$font_grey: #757475;

$black: #000;
$white: #FFF;

$dropDownHover: #F5F5F5;

$modalTitleColor: #7B7B7B;
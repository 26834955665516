#layout--login {
    background: {
        repeat: no-repeat;
        size: cover;
        attachment: fixed;
    }
    padding: {
        top: pxToRem(60);
    }
    margin: {
        left: auto;
        right: auto;
    }
    max-width: calc(100% - #{pxToRem(30)});

    @include wfl-device-pixel-ratio(1.5, max) {
        background: {
            image: url("../images/login/bg_login.png");
        }
    }

    @include wfl-device-pixel-ratio(1.5001, min) {
        background: {
            image: url("../images/login/bg_login@2x.png");
        }
    }

    .content {
        flex: {
            grow: 0;
            shrink: 0;
        }
        display: block;
        align-self: center;
        background: {
            color: $white;
        }
        text-align: center;
        padding: pxToRem(40) pxToRem(15) pxToRem(50);
        width: pxToRem(600);
        max-width: 100%;
        margin: {
            bottom: pxToRem(60);
        }
    }

    .login--logo {
        width: pxToRem(312);
        max-width: 100%;
        height: auto;
        margin: {
            bottom: pxToRem(40);
        }
    }

    .user-create {
        h1 {
            display: none;
        }
    }

    .modalTable-form {
        width: pxToRem(340);
        max-width: 100%;
        margin: {
            left: auto;
            right: auto;
        }
    }

    .form-group {
        #user-executive label{
            margin: {
                left: pxToRem(10);
                right: pxToRem(10);
            }
        }
    }
}

.site-login {
    width: 100%;

    h3 {
        color: $font_grey;
        font: {
            size: 1rem;
            weight: $fontWeightLight;
        }
        margin: {
            bottom: pxToRem(34);
        }
    }
}

#login-form, #request-password-reset-form, #reset-password-form {
    width: pxToRem(340);
    max-width: 100%;
    margin: {
        left: auto;
        right: auto;
    }

    .control-label {
        float: left;
        font: {
            size: pxToRem(12);
            weight: $fontWeightMedium;
        }
        line-height: 1;
    }

    .field--user-name {
        margin: {
            bottom: 0.5rem;
        }
    }

    .login--link {
        color: $ci_red;
        line-height: 1;
        margin: {
            top: 0.25rem;
            bottom: 0.5rem;
        }
        font: {
            weight: $fontWeightMedium;
        }
    }

    .login--button {
        width: pxToRem(185);
    }

    .reset-password {
        margin: {
            bottom: pxToRem(14);
        }
    }
    
    .form-group {
        &:last-of-type {
            margin: {
                bottom: 0;
            }
        }
    }
}

#request-password-reset-form {
    .field--email {
        margin: {
            bottom: pxToRem(30);
        }
    }
}

@media only screen and (min-width: $bootstrap-md) {
    #layout--login {
        padding: {
            top: pxToRem(140);
        }
        .content {
            margin: {
                bottom: pxToRem(140);
            }
        }
    }
}
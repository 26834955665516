/*
Box shadow mixin
==============================================
The mixins provides you a cross-browser compatible method to set a box-shadow.

Please refer to <http://caniuse.com/css-boxshadow> to see the browser support table of the feature.

######Example:
`.box-shadow([h-shadow-value], [v-shadow-value], [blur-amount], [spread-amount], [shadow-color]);`

`@param: {Number} x: Offset to the x axis (Default: 0)`<br/>
`@param: {Number} y: Offset to the y axis (Default: 0)`<br/>
`@param: {Number} blur: Bluring radius (Default: 1px)`<br/>
`@param: {String} color: Color of the shadow (Default: #000)`<br/>
*/

@mixin box-shadow($shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2)) {
    box-shadow: $shadow;
}

@mixin reset-box-shadow() {
    $shadow: 0 0 0 transparent;
    box-shadow: $shadow;
}